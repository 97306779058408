import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'ICS',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
    description: `Designed the new ICS branding, and color scheme. We designed the website to reflect an updated brand aesthetic and a simple clean approach. The biggest challenge was simplifying an over complicated product offering in the websites navigation.`,
    link: 'ics-support.com',
  },
  caseId: 'ics',
  contents: ['image0.jpg', 'image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 'image5.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
